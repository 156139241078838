import moment from "../plugins/moment";
import { escapeHTML } from "../utils";
import TICKET_API from "../api/ticket";
import Swal from "sweetalert2";
import uniqid from "uniqid";
import { showConfirmAlert } from "../utils/alert";
import { mapGetters } from "vuex";

export const CalendarDragableMixin = {
  data() {
    return {
      dragData: {
        start: undefined,
        end: undefined
      },
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      dragTime: null,
      createStart: null,
      hasDragged: false,
      extendOriginal: null,
      addEventDialog: false,
      event: {
        start: undefined,
        end: undefined,
        content: undefined,
        reservation: "Salle 1"
      },
      eventLoading: false
    };
  },
  methods: {
    close() {
      this.selectedOpen = false;
      this.addEventDialog = false;
      this.updateDialog = false;
    },
    show({ event }) {
      this.dragData = event;
      if (this.isMobile) {
        //this.dragEvent = event
      }
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);
      if (
        (this.dragEvent && this.dragTime === null) ||
        (this.isMobile && this.dragData.start && this.dragTime === null)
      ) {
        return;
      } else {
        this.createStart = this.roundTime(mouse);

        this.createEvent = {
          name: `Event #${this.events2.length}`,
          color: this.rndElement(this.colors),
          start: moment(this.createStart).format("YYYY-MM-DD HH:mm"),
          end: moment(this.createStart)
            .add(1, "h")
            .format("YYYY-MM-DD HH:mm"),
          timed: true
        };
        this.events2.push(this.createEvent);
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime !== null) {
        let start = this.dragEvent.start;
        start = new Date(start).getTime();

        let end = this.dragEvent.end;
        end = new Date(end).getTime();
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        let newStart = this.roundTime(newStartTime);
        let newEnd = newStart + duration;
        newStart = moment(newStart).format("YYYY-MM-DD HH:mm");
        newEnd = moment(newEnd).format("YYYY-MM-DD HH:mm");
        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);
        this.createEvent.start = moment(min).format("YYYY-MM-DD HH:mm");
        this.createEvent.end = moment(max).format("YYYY-MM-DD HH:mm");
        this.hasDragged = true;
      }
    },
    endDrag() {
      if (!this.createEvent) return;
      let create = this.createEvent;
      this.dragTime = null;
      this.dragEvent = null;
      const data = this.createEvent;
      if (data) {
        if (!this.hasDragged) {
          const createStart = this.roundTime(this.createStart, true, 60);
          data.start = moment(createStart).format("YYYY-MM-DD HH:mm");
          data.end = moment(createStart)
            .add(1, "h")
            .format("YYYY-MM-DD HH:mm");
        }

        this.event.start = data.start;
        this.event.end = data.end;

        this.addEventDialog = true;
      }
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
      this.overlayStatus = true;
      this.hasDragged = false;
      if (!create) {
        this.updateDialog = true;
        this.addEventDialog = true;
      }
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events2.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
      this.hasDragged = false;
    },
    roundTime(time, down = true, roundTo = 15) {
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    closeEventDialog() {
      this.addEventDialog = false;
      this.event = {
        start: undefined,
        end: undefined,
        content: undefined
      };
      this.events2.splice(this.events2.length - 1, 1);
    }
  },
  watch: {
    selectedOpen() {
      if (this.isMobile && !this.selectedOpen) {
        this.dragData = {
          start: undefined,
          end: undefined
        };
      }
    }
  }
};

export const BaseCalendarMixin = {
  data: () => ({
    toggle_type: 2,
    types: ["day", "week", "month"],
    value: "",
    start: null,
    today: moment().format(),
    end: null,
    weekday: [1, 2, 3, 4, 5, 6, 0],
    weekdaymob: [1, 2, 3, 4, 5],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "deep-orange",
      "red lighten-1",
      "purple darken-1",
      "pink darken-1",
      "red accent-3",
      "deep-purple lighten-4",
      "indigo darken-1",
      "blue darken-4"
    ],
    icsColors: [
      "blue-grey darken-3",
      "grey darken-4",
      "grey darken-1",
      "grey darken-2",
      "grey",
      "blue-grey lighten-4",
      "blue-grey lighten-3",
      "blue-grey lighten-2",
      "blue-grey lighten-1",
      "grey darken-3"
    ],
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false
  }),

  methods: {
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getEventColor(event) {
      return event.color;
    },
    formatDate(a, withTime = true) {
      return withTime
        ? `${a.getFullYear()}-${a.getMonth() +
            1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`;
    },
    updateRange({ start, end }) {
      this.start = start;
      this.end = end;
    },
    styleInterval() {
      return {
        display: "flex",
        "flex-wrap": "wrap"
      };
    },
    eventName(event, timedEvent) {
      const name = escapeHTML(event.input["name"]);
      //const comment = escapeHTML(event.input["comment"] || "");
      //const guest = escapeHTML(event.input["guest"] || "");
      if (event.start.hasTime) {
        if (timedEvent) {
          return name;
        } else {
          return name;
        }
      }
      return name;
    },
    intervalFormat(timestamp, short) {
      const suffix = short ? "h " : "";
      return `${timestamp.hour} ${suffix}`;
    },
    formatTime(withTime, ampm) {
      const suffix = ampm ? "h " : "";
      const hour = withTime.hour % 24 || 0;
      const minute = withTime.minute;

      return minute > 0
        ? minute < 10
          ? `${hour}:0${minute}${suffix}`
          : `${hour}:${minute}${suffix}`
        : `${hour}${suffix}`;
    },
    viewDay({ date }) {
      this.toggle_type = 0;
      this.value = date;
    },
    viewWeek({ date }) {
      this.toggle_type = 1;
      this.value = date;
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.updateDialog = false;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    setToday() {
      this.value = "";
    }
  }
};

export const CalendarCrudMixin = {
  data() {
    return {
      createdResevations: [],
      progressValue: 0,
      loadingText: undefined
    };
  },
  methods: {
    round(x) {
      return Math.round(x);
    },
    setRecId(eventPayload, recId, firstEventId = 0) {
      const comment = JSON.parse(eventPayload.comment);
      comment.recid = `${firstEventId}-${recId}`;
      eventPayload.comment = JSON.stringify(comment);
    },
    isRecursiveEvent(reservationID) {
      const event = this.reservations.find(el => el.id === reservationID);
      const comment = event.comment ? JSON.parse(event.comment) : "";
      const recid = comment.recid;
      if (recid) {
        const [idParentEvent, uniqId] = recid.split("-", 2);
        if (idParentEvent == 0) {
          return uniqId; // we return the parent ID if event is parent event
        } else {
          return recid; // we return recid id itself if event is child
        }
      }
      return false;
    },
    getRecursiveEvents(mainEventID, uniqEventsID) {
      return this.reservations.filter(reservation => {
        const comment = JSON.parse(reservation.comment);
        const recid = comment.recid;

        return recid == `${mainEventID}-${uniqEventsID}`;
      });
    },
    formatRepetedEvent(basePayload, input, time, interval) {
      let payloadItems = [];

      const startDate = input.startDate + " " + input.startTime;
      for (let i = 0; i < interval + 1; i++) {
        const v = moment(startDate).add(i, this.time[time]);
        const payload = { ...basePayload };
        payload.begin = moment(v).format("YYYY-MM-DD HH:mm:ss");
        payload.end = moment(v).format("YYYY-MM-DD") + " " + input.endTime;
        payloadItems.push(payload);
      }

      return payloadItems;
    },

    getRepetedEvent(input, time, interval) {
      const basePayload = {
        is_recursive: 0,
        entities_id: input.reservation.entities_id,
        items_id: input.reservation.items_id,
        reservationitems_id: input.reservation.reservationitems_id,
        itemtype: input.reservation.itemtype,
        is_visible: 1,
        comment: JSON.stringify({
          email: input.email,
          tel: input.tel,
          comment: input.content,
          code_pi: input.code_pi,
          guest: input.guest,
          lgt_name: input.eqpt_name,
          state: "1"
        })
      };
      return this.formatRepetedEvent(basePayload, input, time, interval);
    },

    async updateRecursiveReservations({ id, repetition, interval, input }) {
      let recursiveEventRecid = this.isRecursiveEvent(id);
      const currentID = id;
      const isChildEvent = recursiveEventRecid.split("-", 2).length == 2;
      if (isChildEvent) {
        const [idParentEvent, uniqId] = recursiveEventRecid.split("-", 2);

        id = idParentEvent;
        recursiveEventRecid = uniqId;
      }
      let selectedReservation = this.reservations.find(el => el.id == id);
      let selectedEvent = this.events.find(el => el.id == id);

      if (!selectedReservation || !selectedEvent) return;

      const basePayload = {
        is_recursive: 0,
        entities_id: selectedReservation.entities_id,
        items_id: selectedReservation.items_id,
        reservationitems_id: selectedReservation.reservationitems_id,
        itemtype: selectedReservation.itemtype,
        is_visible: 1,
        comment: JSON.stringify({
          email: selectedEvent.email,
          tel: selectedEvent.tel,
          comment: selectedEvent.content,
          code_pi: selectedEvent.code_pi,
          guest: selectedEvent.guest,
          lgt_name: selectedEvent.eqpt_name,
          state: "1"
        })
      };

      const repeatedEvents = this.formatRepetedEvent(
        basePayload,
        input,
        repetition,
        interval
      );
      const baseReservationPayload = repeatedEvents.shift();

      this.overlay = true;

      const payload = {
        begin: baseReservationPayload.begin,
        end: baseReservationPayload.end
      };
      const alertTitle = "Reservation récurrente! ";
      const alertText =
        "Cette reservation est recurrente. Voulez vous modifier toutes les reservations récurrentes ou celle-ci uniquement ?";
      const deniedText = `Cette réservation unique`;

      const confirmedCallback = async () => {
        this.loadingText = "Modification en cours";
        try {
          await TICKET_API.updateReservation(id, payload);
          let selectedIndex = this.events.findIndex(el => el.id == id);
          selectedEvent.start = payload.begin;
          selectedEvent.end = payload.end;
          this.events.splice(selectedIndex, 1, selectedEvent);
          await this.deleteRecursiveEvents(id, recursiveEventRecid);
          this.progressValue = 0;
          this.loadingText = "Creation des reservations recurrentes en cours";
          await this.createRecursiveReservations(
            selectedEvent,
            repeatedEvents,
            recursiveEventRecid
          );
          this.resetDialogData();
          this.selectedOpen = !isChildEvent;
          this.event = {
            start: undefined,
            end: undefined,
            content: undefined,
            reservation: undefined
          };
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: err.response.data
          });
          this.resetDialogData();
        }
      };

      const deniedCallback = () => {
        const body = {
          begin: `${input.startDate} ${input.startTime}`,
          end: `${input.endDate} ${input.endTime}`
        };
        this.updateReservation({ ...body, id: currentID });
      };

      const cancelCallback = () => {
        this.resetDialogData();
      };

      showConfirmAlert(
        alertTitle,
        alertText,
        deniedText,
        confirmedCallback,
        deniedCallback,
        cancelCallback
      );
    },
    async createRecursiveReservations(firstEvent, events, recId) {
      for (const element of events) {
        const payload = element;
        this.setRecId(payload, recId, firstEvent.id);
        const res = await TICKET_API.createReservation(element);
        this.createdResevations.push(res.data.id);
        let trueName = firstEvent.name;
        const newEvent = {
          name: trueName,
          //color: this.rndElement(this.colors),
          color: "grey",
          start: moment(payload.begin).format("YYYY-MM-DD HH:mm:ss"),
          end: moment(payload.end).format("YYYY-MM-DD HH:mm:ss"),
          timed: true,
          id: res.data.id,
          email: firstEvent.email,
          tel: firstEvent.tel,
          comment: firstEvent.content,
          guest: firstEvent.guest
        };
        this.events.push(newEvent);
        this.reservations.push({ ...payload, id: res.data.id });
        this.progressValue += 100 / (events.length + 1);
      }
    },
    addRecursiveReservations({ input, repetition, interval }) {
      const events = this.getRepetedEvent(input, repetition, interval);
      const baseReservationPayload = events.shift();
      const recId = uniqid();

      this.setRecId(baseReservationPayload, recId);

      this.overlay = true;
      const total = events.length + 1;

      TICKET_API.createReservation(baseReservationPayload)
        .then(async ({ data }) => {
          let trueName = `${input.reservation.name}-${input.content}`;
          const newEvent = {
            name: trueName,
            //color: this.rndElement(this.colors),
            color: "grey",
            start: moment(baseReservationPayload.begin).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            end: moment(baseReservationPayload.end).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            timed: true,
            id: data.id,
            email: input.email,
            tel: input.tel,
            comment: input.content,
            guest: input.guest,
            commercial: input.commercial
          };
          this.events2.pop();
          this.events.push(newEvent);
          this.reservations.push({ ...baseReservationPayload, id: data.id });

          this.createdResevations.push(data.id);
          this.progressValue += 100 / total;

          try {
            await this.createRecursiveReservations(newEvent, events, recId);
            this.addEventDialog = false;
            this.overlay = false;
            this.event = {
              start: undefined,
              end: undefined,
              content: undefined,
              reservation: undefined
            };
            Swal.fire(
              "Merci de votre réservation!",
              "Vous allez recevoir un mail du gestionnaire dès acception",
              "success"
            ).then(() => {
              this.progressValue = 0;
            });
          } catch (err) {
            this.addEventDialog = false;
            this.overlay = false;
            Promise.all(
              this.createdResevations.map(el => this.removeReservation(el))
            );
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html:
                err && err.response && err.response.data
                  ? err.response.data
                  : "Error de creation"
            });
          }
        })
        .catch(err => {
          this.addEventDialog = false;
          this.overlay = false;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html:
              err && err.response && err.response.data
                ? err.response.data
                : "Erreur de creation"
          });
        });
    },
    addReservation({ input }) {
      const payload = {
        begin: input.start,
        end: input.end,
        is_recursive: 0,
        entities_id: input.reservation.entities_id,
        items_id: input.reservation.items_id,
        reservationitems_id: input.reservation.reservationitems_id,
        itemtype: input.reservation.itemtype,
        is_visible: 1,
        comment: JSON.stringify({
          email: input.email,
          tel: input.tel,
          comment: input.content,
          code_pi: input.code_pi,
          guest: input.guest,
          lgt_name: input.eqpt_name,
          state: input.state,
          commercial: input.commercial
        })
      };
      const payloadreminder = {
        begin_view_date: input.start,
        end_view_date: input.end,
        is_recursive: 0,
        users_id: this.getUserId,
        is_planned: 1,
        name: input.code_pi,
        text: input.guest
      };
      const payloadreminderdate = {
        begin: input.start,
        end: input.end
      };

      this.overlay = true;
      TICKET_API.createReminder(payloadreminder).then(({ data }) => {
        TICKET_API.updateReminder(data.id, payloadreminderdate);
      });

      TICKET_API.createReservation(payload)
        .then(({ data }) => {
          let trueName = `${input.reservation.name}-${input.content}`;
          this.addEventDialog = false;
          this.overlay = false;
          this.event = {
            start: undefined,
            end: undefined,
            content: undefined,
            reservation: undefined
          };
          const newEvent = {
            name: trueName,
            //color: this.rndElement(this.colors),
            color: "grey",
            start: moment(payload.begin).format("YYYY-MM-DD HH:mm:ss"),
            end: moment(payload.end).format("YYYY-MM-DD HH:mm:ss"),
            timed: true,
            id: data.id,
            email: input.email,
            tel: input.tel,
            comment: input.content,
            guest: input.guest,
            commercial: input.commercial
          };
          this.events2.pop();
          this.events.push(newEvent);
          this.reservations.push({ ...payload, id: data.id });
          if (this.isDirectLink) {
            Swal.fire({
              title: "Merci de votre réservation!",
              text: "Vous allez recevoir un mail du gestionnaire dès acception",
              icon: "success",
              showConfirmButton: false
            });
          } else {
            Swal.fire(
              "Merci de votre réservation!",
              "Vous allez recevoir un mail de confirmation",
              "success"
            );
          }
        })
        .finally(() => {
          if (this.isDirectLink) {
            setTimeout(function() {
              window.location.reload();
            }, 4000);
          }
        })
        .catch(err => {
          this.addEventDialog = false;
          this.overlay = false;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html:
              err && err.response && err.response.data
                ? err.response.data
                : "Error de creation"
          });
        });
    },
    async deleteRecursiveEvents(idVal, recursiveEventRecid, idIni) {
      const events = this.getRecursiveEvents(idVal, recursiveEventRecid);
      const total = events.length + 1;
      this.loadingText = "Suppression des reservations récurrentes en cours";
      for (const event of events) {
        if (event.id >= idIni) {
          await TICKET_API.deleteReservation(event.id);
          const idx = this.events.findIndex(el => el.id == event.id);
          this.events.splice(idx, 1);
          const idx2 = this.reservations.findIndex(el => el.id == event.id);
          this.reservations.splice(idx2, 1);
          this.progressValue += 100 / total;
        }
      }
    },
    deleteSingleReservation(reservtionID, isRecursive = false, idVal) {
      if (reservtionID >= idVal) {
        TICKET_API.deleteReservation(reservtionID)
          .then(() => {
            const idx = this.events.findIndex(el => el.id == reservtionID);
            this.events.splice(idx, 1);
            const idx2 = this.reservations.findIndex(
              el => el.id == reservtionID
            );
            this.reservations.splice(idx2, 1);
            if (isRecursive) {
              this.progressValue = 100;
            }
            Swal.fire(
              "Deleted!",
              "La réservation a éte supprimée",
              "success"
            ).then(() => {
              this.progressValue = 0;
            });
            this.selectedOpen = false;
          })
          .catch(err => {
            this.selectedOpen = false;
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: err.response.data
            });
          })
          .finally(() => {
            this.resetDialogData();
          });
      } else {
        this.resetDialogData();
      }
    },
    deleteReservation(idVal) {
      this.overlay = true;
      const recursiveEventRecid = this.isRecursiveEvent(idVal);
      if (recursiveEventRecid) {
        const alertTitle = " Reservation récurrente! ";
        const alertText =
          "Souhaitez-vous supprimer toutes les futures réservations récurrentes ou celle-ci uniquement?";
        const deniedText = `Celle-ci`;
        const confirmedCallback = async () => {
          try {
            if (recursiveEventRecid.split("-", 2).length == 2) {
              const [id, uniqId] = recursiveEventRecid.split("-", 2);
              await this.deleteRecursiveEvents(id, uniqId, idVal);
              this.deleteSingleReservation(id, true, idVal);
            } else {
              await this.deleteRecursiveEvents(idVal, recursiveEventRecid);
              this.deleteSingleReservation(idVal, true, idVal);
            }
          } catch (err) {
            this.selectedOpen = false;
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html:
                err && err.response && err.response.data
                  ? err.response.data
                  : "Error de suppression"
            });
            this.overlay = false;
            return;
          }
        };

        const deniedCallback = () => {
          this.deleteSingleReservation(idVal, true, idVal);
        };
        showConfirmAlert(
          alertTitle,
          alertText,
          deniedText,
          confirmedCallback,
          deniedCallback,
          () => {
            this.overlay = false;
          }
        );
        this.selectedOpen = false;
      } else {
        Swal.fire({
          title: "Êtes vous sûrs?",
          text: "Vous ne pourrez pas revenir en arrière!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Confirmer"
        }).then(result => {
          if (result.isConfirmed) {
            this.deleteSingleReservation(idVal, true, idVal);
          }
        });
      }
    },
    async removeReservation(reservationId) {
      TICKET_API.deleteReservation(reservationId).then(() => {
        const idx = this.events.findIndex(el => el.id == reservationId);
        this.events.splice(idx, 1);
      });
    },
    async updateReservation(reservation) {
      this.overlay = true;
      const payload = {
        begin: reservation.begin,
        end: reservation.end
      };
      let idReservation = reservation.id;
      try {
        await TICKET_API.updateReservation(idReservation, payload);
        let selected = this.allEvents.filter(el => el.id == idReservation);
        selected[0].start = payload.begin;
        selected[0].end = payload.end;
        this.resetDialogData();
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: err.response.data
        });
        this.overlay = false;
      }
    }
  },
  computed: {
    ...mapGetters(["getUserId"]),
    isDirectLink() {
      return this.$route.meta.directLink;
    }
  }
};
