import Swal from "sweetalert2";

export const showConfirmAlert = (
  title,
  text,
  denyButtonText,
  confirmedCallback,
  deniedCallback,
  cancelCallback
) => {
  Swal.fire({
    title,
    text,
    icon: "warning",
    showCancelButton: true,
    showDenyButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#3085d6",
    denyButtonColor: "orange",
    confirmButtonText: "Toutes",
    denyButtonText,
    cancelButtonText: "Annuler"
  }).then(async resp => {
    if (resp.isConfirmed) {
      confirmedCallback();
    } else if (resp.isDenied) {
      deniedCallback();
    } else {
      cancelCallback();
    }
  });
};
