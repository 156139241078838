<template>
  <v-container class="fill-height" fluid>
    <v-row align="start" justify="center" class="fill-height ml-0 mr-0">
      <v-card-text class="mt-n4 mb-n4">
        <v-progress-linear
          :active="loading"
          indeterminate
          color="primary"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
      <v-col md="12" sm="12">
        <v-card-title class="d-flex justify-center mt-n5">
          <v-row class="mt-n5">
            <v-col cols="6">Réf : {{ formatID(ticketId) }} </v-col>
            <v-col cols="2">
              <v-btn
                v-if="redirectLink"
                class="ml-6"
                small
                dark
                color="white"
                :href="redirectLink"
                target="_blank"
                title="Lien direct"
              >
                <v-icon color="primary" large>mdi-link</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="1">
              <v-btn
                v-if="mailLink"
                class="ml-6"
                small
                dark
                color="white"
                :href="mailLink"
                target="_blank"
                title="Envoyer par mail"
              >
                <v-icon color="primary" large>mdi-email</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <template v-if="this.isAdminUser">
            <v-row class="mt-n5 mb-n5">
              <v-spacer v-if="!isMobile"></v-spacer>
              <v-col cols="6"> Lieu : {{ this.ticket.locations_id }}</v-col>
              <v-col cols="1">
                <v-edit-dialog
                  :return-value.sync="newLocation"
                  large
                  @save="changeLoc(ticket, newLocation)"
                >
                  <v-icon color="primary" dark small>mdi-pencil</v-icon>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">MAJ lieu</div>
                    <v-text-field
                      v-model="newLocation"
                      label="Edit"
                      single-line
                      counter
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </v-col>
              <v-col cols="1">
                <v-btn
                  color="white"
                  dark
                  x-small
                  title="Liste des lieux"
                  @click="getLocVoisines()"
                >
                  <v-icon color="primary" small>
                    mdi-help
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-card-title>
        <ticket-action
          :ticket-id="ticketId"
          :entity-id="entityId"
          :ticket-status="ticket.status"
          @newComment="addNewITILFollowup"
          @newSolution="addNewITILSolution"
          @newTask="addNewTask"
        ></ticket-action>
        <time-line
          v-if="list.length > 0"
          :list="list"
          :selected-ticket="{
            name: ticketName,
            date_creation: ticketDate,
            content: ticket.content
          }"
        ></time-line>
        <v-skeleton-loader
          ref="skeleton"
          type="list-item-avatar-three-line"
          class="mx-auto"
          v-if="loading"
        ></v-skeleton-loader>
        <div class="d-flex flex-column" v-if="loaded && list.length === 0">
          <v-timeline
            :dense="$vuetify.breakpoint.smAndDown"
            class="timeline__wrapper"
          >
            <v-timeline-item left color="warning" icon class="text-right">
              <template slot="icon">
                <v-icon x-large class="white--text"
                  >mdi-information-outline</v-icon
                >
              </template>
              <span slot="opposite">
                <v-chip>
                  <v-icon color="success">access_time </v-icon>
                  {{ moment(ticket.date_creation).format("DD/MM/YYYY  HH:mm") }}
                </v-chip>
              </span>
              <div style="width: 100%">
                <div class="ma-2 text-justify incident pb-2" outlined x-large>
                  <div class="subtitle-1">
                    Déclaration d'intervention:
                  </div>
                  <hr />
                  <div
                    class="subtitle-2 pt-2 pb-2"
                    v-if="ticket.content"
                    v-html="decodeContent(ticket.content)"
                  ></div>
                  <hr />
                </div>
              </div>
            </v-timeline-item>
            <v-timeline-item right color="grey" icon>
              <template slot="icon">
                <v-icon x-large class="white--text"
                  >mdi-information-outline</v-icon
                >
              </template>
              <v-chip class="ma-2" color="grey" outlined>
                Aucun élement de suivi
              </v-chip>
            </v-timeline-item>
          </v-timeline>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "../../plugins/moment";
import { Decoder } from "../../mixins/decodeContent";
import TimeLine from "../TimeLine";
import TicketAction from "../TicketAction";
import { setupTicketsComponent } from "../../composition/setupTicketsData";
import { formatID } from "@/utils";
import { mapGetters } from "vuex";
import { computed } from "@vue/composition-api";
import { PORTAL_CONFIG } from "../../config";
import TICKET_API from "../../api/ticket";

export default {
  name: "TicketDetail",
  components: { TimeLine, TicketAction },
  props: {
    ticketId: {
      type: Number,
      required: true
    },
    ticketName: {
      type: String,
      default: ""
    },
    ticketDate: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      titleText: "",
      loading: true,
      newLocation: "",
      errors: []
    };
  },
  setup(props, { root: { $store } }) {
    const ticketId = props.ticketId;
    const tasks = computed(() =>
      $store.getters.getTicketsTasks.filter(
        task => task.tickets_id === ticketId
      )
    );
    const followUps = computed(() =>
      $store.getters.getAllITILFollowups.filter(
        task => task.items_id === ticketId
      )
    );
    const itSolutions = computed(() =>
      $store.getters.getAllITILSolutions.filter(
        task => task.items_id === ticketId
      )
    );

    return {
      ...setupTicketsComponent(ticketId, tasks, followUps, itSolutions),
      formatID,
      tasks,
      followUps,
      itSolutions
    };
  },
  mixins: [Decoder],
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters([
      "getActiveEntity",
      "getTicketsTasks",
      "getEntity",
      "getSessionToken",
      "getEquipmentByType",
      "isAdminUser"
    ]),
    getStates() {
      let states = this.getActiveEntity.state;
      return states ? JSON.parse(states) : { location: "Emplacement" };
    },
    entityId() {
      return this.getActiveEntity.id ? this.getActiveEntity.id : NaN;
    },
    redirectLink() {
      if (!this.$route.meta.directLink) {
        //s'il y a un token visiteur sur l'entité de niveau 5
        if (isNaN(this.entityId)) {
          return false;
        } else {
          if (this.getActiveEntity.fax) {
            let visitToken = JSON.parse(this.getActiveEntity.fax).user[0];
            return `${PORTAL_CONFIG.WEB_URL}/view/ticket/${this.ticketId}/u/${visitToken}`;
          } else {
            //S'il y a un token visiteur sur l'entité de niveau 4
            let parentEntity = this.getEntity.filter(
              entity =>
                entity.level === 4 &&
                entity.id === this.getActiveEntity.entities_id
            );
            if (parentEntity[0].fax) {
              let visitToken = JSON.parse(parentEntity[0].fax).user[0];
              return `${PORTAL_CONFIG.WEB_URL}/view/ticket/${this.ticketId}/u/${visitToken}`;
            } else {
              //S'il y a un token visiteur sur l'entité de niveau 3
              let grandparentEntity = this.getEntity.filter(
                entity =>
                  entity.level === 3 &&
                  entity.id === parentEntity[0].entities_id
              );
              if (grandparentEntity[0].fax) {
                let visitToken = JSON.parse(grandparentEntity[0].fax).user[0];
                return `${PORTAL_CONFIG.WEB_URL}/view/ticket/${this.ticketId}/u/${visitToken}`;
              }
            }
          }
        }
        return false;

        //return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/request.php?user=${this.getUserToken}&entity=${this.$route.params.id}`;
      } else {
        return false;
      }
    },
    mailLink() {
      if (this.ticket.name) {
        let email = JSON.parse(this.ticket.name).mail;
        let titre = JSON.parse(this.ticket.name).titre;
        if (isNaN(this.entityId)) {
          return false;
        } else {
          return `mailto:${email}?subject=${titre} - Suivi [Keep #${this.ticketId}]&cc=support@keepintouch.immo`;
        }
      } else return false;
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    }
  },
  methods: {
    moment,
    async getLocVoisines() {
      Object.keys(this.getStates).forEach(async el => {
        if (el.toLowerCase().startsWith("plugingenericobject")) {
          await this.loadItemType(el);
        }
        let equips = this.getEquipmentByType(el);
        const equips_data = equips ? equips.data : [];
        let entityN4 = this.getEntity.filter(
          entity =>
            entity.level === 4 && entity.id === this.getActiveEntity.entities_id
        );
        let entityN5 = this.getEntity.filter(
          entity =>
            entity.level === 5 &&
            entity.ancestors_cache.includes(entityN4[0].id)
        );
        let group = [];
        for (let i = 0; i < entityN5.length; i++) {
          group == group.push(entityN5[i].id);
        }
        let eqpts = [];
        const equipsEnt4 = equips_data.filter(equips =>
          group.includes(equips.entities_id)
        );
        for (let j = 0; j < equipsEnt4.length; j++) {
          eqpts == eqpts.push(equipsEnt4[j].id);
          eqpts == eqpts.push(equipsEnt4[j].name + " %");
        }
        alert(
          eqpts
            .toString()
            .replace(/%,/g, "| ")
            .replace(/,/g, "-")
            .replace(/ %/g, "")
        );
      });
    },
    async changeLoc(item, value) {
      let entityDest = this.getEquipmentByType("location").data.filter(
        loc => loc.id == value
      );
      let valueEnt = entityDest[0].entities_id;
      try {
        await TICKET_API.moveTicket(
          this.getSessionToken,
          item,
          value,
          valueEnt
        );
      } catch ({ response }) {
        this.errors.push({
          msg: response.data,
          snackbar: true
        });
      }
      setTimeout(function() {
        window.location.reload();
      }, 4000);
    }
  }
};
</script>

<style scoped></style>
