<template>
  <v-row v-if="isMobile" align="start" class="fill-height" no-gutters>
    <v-col md="12" class="pt-5">
      <presta-editor :comment="note" :title="$t('header.providers_note')">
      </presta-editor>
    </v-col>
    <v-col cols="12" class="px-0 px-md-0">
      <list :tickets="getTickets"></list>
    </v-col>
    <v-col cols="12">
      <tickets-chart :tickets="getTickets"></tickets-chart>
    </v-col>
  </v-row>
  <v-row v-else align="start" class="fill-height mx-6" no-gutters>
    <v-col md="12" class="pt-5">
      <presta-editor :comment="note" :title="$t('header.providers_note')">
      </presta-editor>
    </v-col>
    <v-col cols="12" class="px-0 px-md-0">
      <list :tickets="getTickets"></list>
    </v-col>
    <v-col cols="12">
      <tickets-chart :tickets="getTickets"></tickets-chart>
    </v-col>
  </v-row>
</template>
<script>
import List from "./List";
import TicketsChart from "../prestation/PChart";
import PrestaEditor from "../editor/PrestaEditor";

import USER_API from "../../api/user";

import { mapGetters } from "vuex";

export default {
  name: "Prestation",
  components: { TicketsChart, List, PrestaEditor },
  data() {
    return {
      note: "",
      headers: [
        {
          text: "ID",
          align: "center",
          sortable: "false",
          value: "id",
          class: "t-row"
        },
        {
          text: "Périmètre",
          align: "center",
          sortable: "false",
          value: "name",
          class: "t-row"
        },
        {
          text: "Date",
          align: "center",
          sortable: "false",
          value: "solvedate",
          class: "t-row"
        },
        {
          text: "Société",
          align: "center",
          sortable: "false",
          value: "",
          class: "t-row"
        }
      ]
    };
  },
  created() {
    this.loadNote();
  },
  methods: {
    loadNote() {
      USER_API.getEntityNote(this.$route.params.id).then(({ data }) => {
        const size = data.length;
        if (size > 0) {
          for (let i = 0; i < size; i++) {
            if (data[i].content.substring(0, 5) == "AAAAA") {
              this.note = data[i].content.substring(5);
              break;
            } else {
              this.note = "Pas de note enregistrée";
            }
          }
        } else {
          this.note = null;
        }
      });
    }
  },
  computed: {
    ...mapGetters(["getAllTickets", "getEquipments"]),
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    getTickets() {
      const currentId = +this.$route.params.id;
      return this.getAllTickets.filter(
        ticket =>
          ticket.itilcategories_id === 5 && ticket.entities_id === currentId
      );
    }
  },
  watch: {
    $route() {
      this.note = "merci de patienter";
      this.loadNote();
    },
    active() {
      this.loadNote();
    }
  }
};
</script>
