<template>
  <v-card color="grey lighten-4" min-width="350px" flat>
    <v-toolbar :color="selectedEvent.color" dark class="title">
      <v-btn icon>
        <v-icon>mdi-calendar</v-icon>
      </v-btn>
      <v-toolbar-title v-if="!isResa">
        Dégradation : {{ selectedEvent.name }}
      </v-toolbar-title>
      <v-toolbar-title v-else v-html="selectedEvent.name"></v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text v-if="isResa" align="right" class="title">
      <span v-html="selectedEvent.email"></span> /
      {{ formatTel(selectedEvent.tel) }}<br />
    </v-card-text>
    <v-card-text class="pt-0">
      <span v-html="selectedEvent.comment"></span>
      <span v-html="selectedEvent.details"></span>
      <div v-if="selectedEvent.state == 0">
        <v-btn text color="primary" :href="acceptLink" target="_blank">
          Confirmer la réservation
        </v-btn>
      </div>
      <div v-if="!isDirectLink && isResa" class="dialog-container">
        <v-dialog ref="updateDialog" v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              color="error"
              v-bind="attrs"
              v-on="on"
              @click="setTime(selectedEvent)"
            >
              Cliquez pour modifier les dates/heures
            </v-btn>
          </template>
          <!--
          <update-recursive-reservation
            v-if="recursiveID"
            :selected-event="selectedEvent"
            :event_repeat="repeatType"
            :lastRecursiveEvent="lastRecursiveEvent"
            :overlayStatus="overlayStatus"
            :progress="progress"
            :loadingText="loadingText"
            :parentEvent="parentEvent"
            @closeDialog="$emit('closeSelected')"
            @updateReservation="$emit('updateRecursiveReservation', $event)"
          ></update-recursive-reservation>
          <v-card v-else>
          -->
          <v-card>
            <v-toolbar dark class="title">
              <span class="text-h5">{{ selectedEvent.name }}</span>
            </v-toolbar>
            <v-card-text align="right" class="title">
              <span v-html="selectedEvent.email"></span> /
              {{ formatTel(selectedEvent.tel) }}<br />
            </v-card-text>
            <v-card-text class="mt-n5">
              <v-container>
                <v-row>
                  <v-col cols="5" class="pa-0">
                    <datetime-picker
                      label="Début *"
                      v-model="task.start"
                      :date-error="eventDateError"
                      :error-msg="eventDateError ? dateErrorMsg : undefined"
                    >
                    </datetime-picker>
                  </v-col>
                  <v-col cols="5" offset="2" class="pa-0">
                    <datetime-picker
                      label="Fin *"
                      v-model="task.end"
                      :date-error="eventDateError"
                      :error-msg="eventDateError ? dateErrorMsg : undefined"
                    >
                    </datetime-picker>
                  </v-col>
                </v-row>
              </v-container>
              <small>* obligatoire</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="eventDateError"
                @click="
                  $emit('updateReservation', {
                    begin: task.start,
                    end: task.end,
                    id: selectedEvent.id
                  })
                "
              >
                Save
              </v-btn>
            </v-card-actions>
            <v-overlay :value="overlay2">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
          </v-card>
        </v-dialog>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn text color="secondary" @click="$emit('closeSelected')">
        Fermer
      </v-btn>
      <v-btn
        v-if="!isDirectLink && isResa"
        text
        color="error"
        @click="$emit('deleteReservation', selectedEvent.id)"
        class="ml-auto"
      >
        Supprimer
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import moment from "../../plugins/moment";
import { PORTAL_CONFIG } from "../../config";
import DatetimePicker from "../DatetimePicker.vue";
//import UpdateRecursiveReservation from "./UpdateRecursiveReservation.vue";
import { formatTel } from "../../utils";
import { mapGetters } from "vuex";
export default {
  props: {
    selectedEvent: {
      type: Object,
      required: true
    },
    overlayStatus: {
      type: Boolean,
      required: true
    },
    progress: {
      type: Number,
      default: 0
    },
    loadingText: {
      type: String,
      default: "Loading"
    },
    dragData: {
      type: Object,
      default() {
        return {
          start: undefined,
          end: undefined
        };
      }
    },
    reservations: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  components: {
    DatetimePicker
    //UpdateRecursiveReservation
  },
  data() {
    return {
      task: {
        start: undefined,
        end: undefined
      },
      overlay2: false,
      dateErrorMsg: "Date Interval invalid",
      dialog: false
    };
  },
  methods: {
    formatTel,
    setTime(selectedEvent) {
      this.task.start = moment(selectedEvent.start).format("YYYY-MM-DD HH:mm");
      this.task.end = moment(selectedEvent.end).format("YYYY-MM-DD HH:mm");
    },
    isRecursiveEvent(reservationID) {
      const event = this.reservations.find(el => el.id === reservationID);
      if (!event) return false;
      const comment = JSON.parse(event.comment);
      const recid = comment.recid;
      if (recid) {
        const [id, uniqId] = recid.split("-", 2);
        if (id == 0) {
          return uniqId;
        } else {
          return uniqId;
        }
      }
      return false;
    },
    getRecursiveEvents(mainEventID, uniqEventsID) {
      const $reservations = this.reservations;
      function getEvents(parentID, uniqID) {
        return $reservations.filter(reservation => {
          const comment = JSON.parse(reservation.comment);
          const recid = comment.recid;

          return recid == `${parentID}-${uniqID}`;
        });
      }
      if (this.isChildEvent) {
        const [idParentEvent, uniqId] = this.recursiveID.split("-", 2);
        return getEvents(idParentEvent, uniqId);
      }
      return getEvents(mainEventID, uniqEventsID);
    }
  },
  mounted() {
    if (this.dragData.start) {
      this.setTime(this.dragData);
    }
  },
  watch: {
    overlayStatus(value) {
      this.overlay2 = value;
      if (!this.overlay2) {
        this.dialog = false;
      }
    },
    dragData: {
      handler: function() {
        if (this.dragData.start) {
          this.setTime(this.dragData);
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(["getUserToken"]),
    acceptLink() {
      return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/piresa.php?user=${this.getUserToken}&resaID=${this.selectedEvent.id}&action=accept&email=${this.selectedEvent.email}`;
    },
    eventDateError() {
      if (this.task.start && this.task.end) {
        return moment(this.task.start).isAfter(moment(this.task.end));
      }
      return false;
    },
    isDirectLink() {
      return this.$route.meta.directLink;
    },
    isResa() {
      return !this.selectedEvent.color.includes("grey");
    },
    recursiveID() {
      if (!this.selectedEvent) return false;
      return this.isRecursiveEvent(this.selectedEvent.id);
    },
    isChildEvent() {
      return this.recursiveID.split("-", 2).length == 2;
    },
    repeatType() {
      if (!this.recursiveID) return -1;
      let events = this.getRecursiveEvents(
        this.selectedEvent.id,
        this.recursiveID
      );
      let event = this.selectedEvent;
      if (this.isChildEvent) {
        const idParentEvent = this.recursiveID.split("-", 2)[0];
        event = this.reservations.find(el => el.id == idParentEvent);
        event.start = event.begin;
      }
      const firstChildEvent = events[0];
      if (!firstChildEvent) return 1;
      const diffDays = moment(firstChildEvent.begin).diff(
        moment(event.start),
        "days"
      );
      if (diffDays == 1) return 0;
      const diffWeeks = moment(firstChildEvent.begin).diff(
        moment(event.start),
        "weeks"
      );
      if (diffWeeks == 1) return 1;

      return 2;
    },
    lastRecursiveEvent() {
      if (!this.recursiveID) return null;
      let events = this.getRecursiveEvents(
        this.selectedEvent.id,
        this.recursiveID
      );
      if (this.isChildEvent) {
        const [idParentEvent, uniqId] = this.recursiveID.split("-", 2);
        events = this.getRecursiveEvents(idParentEvent, uniqId);
      }
      return events[events.length - 1];
    },
    parentEvent() {
      if (!this.recursiveID) return null;
      if (this.recursiveID.split("-", 2).length == 2) {
        const parentEventId = this.recursiveID.split("-", 2)[0];
        return this.reservations.find(el => el.id == parentEventId);
      }
      return null;
    }
  }
};
</script>
<style></style>
