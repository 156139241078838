<template>
  <v-card class="elevation-3 mt-6">
    <v-card v-if="isMobile" flat>
      <doc-filter-mobile
        :selectedCategories="selectedCategories"
        :dateRange="dateRange"
        :selectedNames="selectedNames"
        :authorizedDocs="authorizedDocs"
        @updatedcategories="selectedCategories = $event"
        @updatednames="selectedNames = $event"
        @updatedranges="dateRange = $event"
      ></doc-filter-mobile>
      <v-row justify="center">
        <v-col cols="10" class="mt-n4">
          <v-text-field
            cols="8"
            v-model="search"
            append-icon="search"
            :label="$t('tableStatus.searchName')"
            single-line
            hide-details
            class="mt-n2"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-else flat class="mx-10">
      <v-card-title class="headline display-1">
        {{ $t("index.documents") }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          :label="$t('tableStatus.searchName')"
          single-line
          hide-details
          class="mt-n2"
        >
        </v-text-field>
      </v-card-title>
      <v-card-title>
        <add-new-doc-modal
          :categories="categories"
          @newdocadded="documentsList.push($event)"
        ></add-new-doc-modal>
      </v-card-title>
      <v-card-text>
        <v-card-text class="elevation-2 mb-2 grey lighten-5">
          <doc-filter
            :selectedCategories="selectedCategories"
            :dateRange="dateRange"
            :selectedNames="selectedNames"
            :authorizedDocs="authorizedDocs"
            @updatedcategories="selectedCategories = $event"
            @updatednames="selectedNames = $event"
            @updatedranges="dateRange = $event"
          ></doc-filter>
        </v-card-text>
      </v-card-text>
    </v-card>
    <doc-list :docList="docList" :loadingDoc="loadingDoc"></doc-list>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "../../plugins/moment";
import USER_API from "../../api/user";
import { permissions } from "../../utils";
import AddNewDocModal from "./AddNewDocModal.vue";
import DocList from "./DocList.vue";
import DocFilter from "./DocFilter.vue";
import DocFilterMobile from "./DocFilterMobile.vue";

export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AddNewDocModal,
    DocList,
    DocFilter,
    DocFilterMobile
  },
  data() {
    return {
      documentsList: [],
      loadingDoc: false,
      activeEntity: null,
      selectedNames: [],
      selectedCategories: [],
      search: "",
      dateRange: {},
      categories: []
    };
  },
  computed: {
    ...mapGetters(["getUserProfiles", "getUserId"]),

    docNames() {
      return this.authorizedDocs.map(doc => doc.name);
    },
    docCategories() {
      let categories = this.authorizedDocs.map(doc => {
        if (doc.documentcategories_id === 0) {
          return this.$t("labels.no_category");
        }
        return doc.documentcategories_id;
      });
      return [...new Set(categories)];
    },
    authorizedDocs() {
      return this.documentsList.filter(doc => {
        const comment = "" + doc.comment;
        const per = comment.substring(0, 3);
        if (doc.documentcategories_id == "Support")
          return this.checkDocPermission("XXX");
        return this.checkDocPermission(per);
      });
    },
    filteredDocList() {
      return this.authorizedDocs.filter(doc => {
        if (doc.documentcategories_id === 0) {
          doc.documentcategories_id = this.$t("labels.no_category");
        }
        const dateCreation = moment(
          doc.date_creation,
          "YYYY-MM-Do HH:mm ss"
        ).format("YYYY-MM-DD");

        return (
          (this.selectedNames.length === 0 ||
            this.selectedNames.includes(doc.name)) &&
          (this.selectedCategories.length === 0 ||
            this.selectedCategories.includes(doc.documentcategories_id)) &&
          this.filterByDate(dateCreation)
        );
      });
    },
    docList() {
      if (!this.search) return this.filteredDocList;
      return this.filteredDocList.filter(doc => {
        if (doc.documentcategories_id === 0)
          doc.documentcategories_id = this.$t("labels.no_category");

        return (
          doc.name.toLowerCase().search(this.search.toLowerCase()) > -1 ||
          doc.documentcategories_id
            .toLowerCase()
            .search(this.search.toLowerCase()) > -1
        );
      });
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    }
  },
  created() {
    this.loadData();
    this.loadCategories();
  },
  methods: {
    getEntityParentName(entity) {
      let parent = this.getEntity.find(ent => ent.id === entity.entities_id);
      return parent ? parent.name : "";
    },
    loadData() {
      if (this.active) {
        USER_API.loadDoc()
          .then(response => {
            const docs = response.data;
            this.documentsList = docs;
            /*.filter(
              doc => doc.entities_id === this.$route.params.id
            );*/
          })
          .finally(() => {
            this.loadingDoc = false;
          });
      }
    },
    filterByDate(date) {
      if (!this.dateRange.start && !this.dateRange.end) return true;
      return this.dateRange.start <= date && this.dateRange.end >= date;
    },
    checkDocPermission(permission) {
      const permissionSize = permission.length;
      if (permissionSize > 2) {
        const publique = permission[0];
        const occupant = permission[1];
        const investisseur = permission[2];

        let per = false;
        if (publique.toUpperCase() === "X") {
          per = permissions.profileDocPermission(
            "publique",
            this.getUserProfiles
          );
          if (per) return true;
        }

        if (occupant.toUpperCase() === "X") {
          per = permissions.profileDocPermission(
            "occupant",
            this.getUserProfiles
          );
          if (per) return true;
        }
        if (investisseur.toUpperCase() === "X") {
          per = permissions.profileDocPermission(
            "investisseur",
            this.getUserProfiles
          );

          if (per) return true;
        }

        return false;
      }
      return false;
    },
    loadCategories() {
      USER_API.getDocumentCategories().then(({ data }) => {
        if (this.getUserProfiles[0] === 22) {
          this.categories = data.filter(el =>
            el.ancestors_cache.includes("14")
          );
        } else {
          //on masque a priori les doc techniques de tickets
          this.categories = data.filter(el => el.id != "5");
        }

        // Initialiser les selectedCategories
        this.selectedCategories = this.categories.map(el => el.completename);
      });
    }
  },

  watch: {
    $route() {
      this.loadData();
    },
    active() {
      this.loadData();
    }
  }
};
</script>
