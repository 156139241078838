<template>
  <v-card
    v-if="!isNullRow"
    elevation="5"
    class="my-2 mx-2"
    @click="rowClicked($event, item)"
  >
    <v-card-title class="subheading font-weight-bold"
      ><template v-if="isMobile">
        {{ formatID(item.id) }}_{{ triche(getAttribUser(item.id))
        }}{{ priority(item.priority) }} -
        {{ JSON.parse(item.name).titre.slice(0, 10) }}
        <v-spacer></v-spacer>
        <v-btn
          v-if="isExpanded"
          fab
          dark
          small
          @click.prevent="removeFromExtand(item)"
        >
          <v-icon :style="{ color: getStatusColor(item.status) }"
            >mdi-chevron-up</v-icon
          >
        </v-btn>
        <v-btn
          v-else
          fab
          small
          @click.prevent="expandRow(item)"
          :style="{ 'background-color': getStatusColor(item.status) }"
          ><v-icon color="white">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <template v-else
        >{{ formatID(item.id) }}_{{ triche(getAttribUser(item.id))
        }}{{ priority(item.priority) }} -
        {{ JSON.parse(item.name).titre }}
        <v-spacer></v-spacer>
        <v-btn
          v-if="isExpanded"
          fab
          dark
          small
          @click.prevent="removeFromExtand(item)"
        >
          <v-icon :style="{ color: getStatusColor(item.status) }"
            >mdi-chevron-up</v-icon
          >
        </v-btn>
        <v-btn
          v-else
          fab
          small
          @click.prevent="expandRow(item)"
          :style="{ 'background-color': getStatusColor(item.status) }"
          ><v-icon color="white">mdi-chevron-down</v-icon>
        </v-btn></template
      >
    </v-card-title>

    <v-divider></v-divider>

    <v-list dense v-if="isExpanded">
      <v-list-item v-for="header in headers" :key="header.value">
        <v-list-item-content>{{ header.text }}:</v-list-item-content>
        <v-list-item-content class="align-end">
          <slot :name="header.value" :item="item" :header="header">
            {{ item[header.value] }}
          </slot>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import {
  getObjectValueByPath,
  statusList,
  formatID,
  triche,
  priority
} from "../../utils";
import USER_API from "../../api/user";

export default {
  data() {
    return {
      ticketsUsers: []
    };
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    isExpanded: {
      type: Boolean,
      default: false
    }
  },
  provide() {
    return {
      getAttribUser: this.getAttribUser
    };
  },
  computed: {
    isNullRow() {
      return !getObjectValueByPath(this.item, this.headers[0].value);
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 400;
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    formatID,
    triche,
    priority,
    async loadData() {
      this.loading = true;
      this.ticketsUsers = (await USER_API.getTicketsUsers()).data;
      this.loading = false;
    },
    getAttribUser(ticketId) {
      let ticketUser = this.ticketsUsers.find(
        user => user.tickets_id === ticketId
      );
      return ticketUser ? ticketUser.users_id : "";
    },
    expandRow(row) {
      this.$emit("expand", row.id);
    },
    removeFromExtand(row) {
      this.$emit("n-expand", row.id);
    },
    rowClicked(e, item) {
      if (e.target.tagName === "DIV") this.$emit("row-clicked", item);
    },
    getStatusColor(status) {
      return status
        ? statusList(this.$root.$i18n.locale).find(el => el.status === status)
            .color
        : "";
    }
  }
};
</script>
