<template>
  <v-card v-if="isSmallMobile" min-width="340" class="elevation-0">
    <v-tabs
      class="tabs-border"
      right=""
      active-class="active-tab"
      hide-slider
      show-arrows
    >
      <v-tab
        class="tabsmall"
        v-for="(item, index) in allowedOnglets"
        :key="index"
      >
        <v-icon>{{ item.icon }}</v-icon>
      </v-tab>
      <v-tab-item v-for="item in allowedOnglets" disabled :key="item.number">
        <onglet-synthese-carto v-if="item.number === 1"></onglet-synthese-carto>
        <onglet-synthese-technique
          :active="isActivate"
          v-if="item.number === 2"
        ></onglet-synthese-technique>
        <onglet-prestation
          v-if="item.number === 3"
          :note="note"
        ></onglet-prestation>
        <onglet-economie
          :active="isActivate"
          v-if="item.number === 4"
        ></onglet-economie>
        <onglet-document
          :active="isActivate"
          v-if="item.number === 5"
        ></onglet-document>
        <onglet-site-life v-if="item.number === 6"></onglet-site-life>
        <onglet-access
          :active="isActivate"
          v-if="item.number === 7"
        ></onglet-access>
      </v-tab-item>
    </v-tabs>
  </v-card>
  <v-row v-else align="start" justify="center" class="fill-height">
    <v-col cols="12">
      <v-card class="elevation-0">
        <v-tabs
          class="tabs-border"
          right=""
          active-class="active-tab"
          hide-slider
          show-arrows
        >
          <template v-if="isMobile">
            <v-tab v-for="(item, index) in allowedOnglets" :key="index">
              <v-icon>{{ item.icon }}</v-icon>
            </v-tab>
          </template>
          <template v-else>
            <v-tab
              v-for="(item, index) in allowedOnglets"
              :key="index"
              class="text-left"
            >
              <v-icon left>{{ item.icon }}</v-icon>
              {{ item.name }}
            </v-tab>
          </template>
          <v-btn
            fab
            elevation="1"
            small
            :href="redirectLink"
            dark
            color="white"
            target="_blank"
            title="Ajouter une intervention"
            v-if="this.completeName && redirectLink"
          >
            <v-icon color="primary" large>mdi-qrcode</v-icon> </v-btn
          ><i class="pa-1"></i>
          <v-btn
            fab
            elevation="1"
            small
            :href="redirectLink2"
            dark
            color="white"
            target="_blank"
            title="Ajouter un index de comptage"
            v-if="this.completeName && show"
          >
            <v-icon color="primary" large>mdi-chart-bar</v-icon>
          </v-btn>
          <i class="pa-1"></i>
          <v-btn
            fab
            elevation="1"
            small
            :href="redirectLink3"
            dark
            color="white"
            target="_blank"
            title="Réserver une ressource"
            v-if="this.completeName && show"
          >
            <v-icon color="primary" large>mdi-calendar-cursor</v-icon>
          </v-btn>
          <i class="pa-1"></i>
          <v-tab-item
            v-for="item in allowedOnglets"
            disabled
            :key="item.number"
          >
            <onglet-synthese-carto
              v-if="item.number === 1"
            ></onglet-synthese-carto>
            <onglet-synthese-technique
              :active="isActivate"
              v-if="item.number === 2"
            ></onglet-synthese-technique>
            <onglet-prestation
              v-if="item.number === 3"
              :note="note"
            ></onglet-prestation>
            <onglet-economie
              :active="isActivate"
              v-if="item.number === 4"
            ></onglet-economie>
            <onglet-document
              :active="isActivate"
              v-if="item.number === 5"
            ></onglet-document>
            <onglet-site-life v-if="item.number === 6"></onglet-site-life>
            <onglet-access
              :active="isActivate"
              v-if="item.number === 7"
            ></onglet-access>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { permissions } from "../utils";
import USER_API from "../api/user";
import OngletEconomie from "../components/economie/OngletEconomie";
import OngletSyntheseTechnique from "../components/synthese/OngletSyntheseTechnique";
import OngletSiteLife from "../components/site/OngletSiteLife";
import OngletPrestation from "../components/prestation/Index";
import OngletDocument from "../components/document/Index";
import OngletAccess from "../components/access/OngletAccess";
import OngletSyntheseCarto from "../components/synthese/OngletSyntheseCarto";
import { PORTAL_CONFIG } from "../config";

export default {
  components: {
    OngletEconomie,
    OngletSyntheseTechnique,
    OngletSiteLife,
    OngletPrestation,
    OngletDocument,
    OngletAccess,
    OngletSyntheseCarto
  },
  data() {
    return {
      tab: null,
      completeName: "",
      onglets: [
        {
          name: "Carto",
          icon: "mdi-map-marker-multiple",
          number: 1
        },
        {
          name: "Technique",
          icon: "mdi-wrench",
          number: 2
        },
        {
          name: "Prestation",
          icon: "mdi-broom",
          number: 3
        },
        {
          name: "Economique",
          icon: "mdi-currency-eur",
          number: 4
        },
        {
          name: "Document",
          icon: "mdi-file",
          number: 5
        },
        {
          name: "Calendrier",
          icon: "mdi-calendar",
          number: 6
        },
        {
          name: "Acces",
          icon: "mdi-key",
          number: 7
        }
      ],
      isActivate: false,
      note: null
    };
  },
  computed: {
    ...mapGetters([
      "getUserProfile",
      "getUserProfiles",
      "getEntity",
      "isAuth",
      "getActiveEntity",
      "getUserToken",
      "isAdminUser"
    ]),
    selectedEntity() {
      return this.getEntity.find(
        entity => entity.id === Number.parseInt(this.$route.params.id)
      );
    },
    allowedOnglets() {
      return this.onglets.filter(item => this.displaySection(item.number));
    },
    redirectLink() {
      //s'il y a un token visiteur sur l'entité de niveau 5
      if (this.isAdminUser) {
        return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/request.php?user=${this.getUserToken}&entity=${this.$route.params.id}`;
      } else if (this.getActiveEntity.fax) {
        let visitToken = JSON.parse(this.getActiveEntity.fax).user[0];
        return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/request.php?user=${visitToken}&entity=${this.$route.params.id}`;
      } else {
        //S'il y a un token visiteur sur l'entité de niveau 4
        let parentEntity = this.getEntity.filter(
          entity =>
            entity.level === 4 && entity.id === this.getActiveEntity.entities_id
        );
        if (parentEntity[0].fax) {
          let visitToken = JSON.parse(parentEntity[0].fax).user[0];
          return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/request.php?user=${visitToken}&entity=${this.$route.params.id}`;
        } else {
          //S'il y a un token visiteur sur l'entité de niveau 3
          let grandparentEntity = this.getEntity.filter(
            entity =>
              entity.level === 3 && entity.id === parentEntity[0].entities_id
          );
          if (grandparentEntity[0].fax) {
            let visitToken = JSON.parse(grandparentEntity[0].fax).user[0];
            return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/request.php?user=${visitToken}&entity=${this.$route.params.id}`;
          }
        }
      }
      return false;

      //return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/request.php?user=${this.getUserToken}&entity=${this.$route.params.id}`;
    },
    redirectLink2() {
      return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/compteur.php?user=${this.getUserToken}&entity=${this.$route.params.id}`;
    },
    redirectLink3() {
      return `${PORTAL_CONFIG.SERVER_WEB}/${PORTAL_CONFIG.APP_NAME}/${PORTAL_CONFIG.CLIENT_NAME}/resa.php?user=${this.getUserToken}&entity=${this.$route.params.id}`;
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.lg
      );
    },
    isSmallMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.sm
      );
    },
    isSupport() {
      if (this.getEntity.filter(el => el.level == 3).length > 0) {
        return this.getEntity.filter(el => el.level == 3)[0].id == 308;
      }
      return false;
    },
    show() {
      return this.isAdminUser && !this.isSupport;
    }
  },
  methods: {
    ...mapMutations(["setActiveEntity"]),
    displaySection(sectionNumber) {
      let flag = false;
      for (let i = 0; i < this.getUserProfiles.length; i++) {
        if (permissions.shouldDisplay(sectionNumber, this.getUserProfiles[i])) {
          flag = true;
        }
      }
      if (this.isSupport && [3, 4, 6, 7].includes(sectionNumber)) flag = false;
      return flag;
    },
    loadActiveEntity() {
      this.isAuth &&
        USER_API.setActiveEntity(this.$route.params.id).then(() => {
          this.isActivate = true;
          this.setActiveEntity(this.selectedEntity);
          this.completeName = this.selectedEntity.completename.split(">")[1];
          this.reloadUserFullSession();
          //this.loadNote();
        });
    },
    reloadUserFullSession() {
      USER_API.getFullSession().then(({ data }) => {
        let userId = data.session.glpiID;
        let { glpigroups } = data.session;
        this.$store.commit("setUserID", userId);
        this.$store.commit("setGLPGroups", glpigroups);
      });
    },
    loadNote() {
      USER_API.getEntityNote(this.$route.params.id).then(({ data }) => {
        const size = data.length;
        if (size > 0) {
          for (let i = 0; i < size; i++) {
            if (data[i].content.substring(0, 5) == "AAAAA") {
              this.note = data[i].content.substring(5);
              break;
            } else {
              this.note = "Pas de note enregistrée";
            }
          }
        } else {
          this.note = null;
        }
      });
    }
  },
  mounted() {
    if (this.selectedEntity && this.$route.params.id) {
      this.loadActiveEntity();
    }
  },
  watch: {
    selectedEntity() {
      if (this.selectedEntity && this.$route.params.id) {
        this.loadActiveEntity();
      }
    },
    $route() {
      if (this.selectedEntity && this.$route.params.id) {
        this.loadActiveEntity();
      }
    }
  }
};
</script>
<style>
@media only screen and (max-width: 539px) and (min-width: 240px) {
  .active-tab::before {
    opacity: 0.12 !important;
    margin: 30px;
  }
}
@media only screen and (max-width: 2800px) and (min-width: 540px) {
  .active-tab::before {
    opacity: 0.12 !important;
  }
}
.tabs-border .v-slide-group__content {
  border-bottom: 2px solid !important;
}
.tabsmall {
  margin: -20px;
}
</style>
