<template>
  <v-card>
    <v-card-title>
      <span :class="{ headline: $vuetify.breakpoint.mdAndUp }">
        Réservation</span
      ><v-spacer></v-spacer>
      <v-card-actions>
        <v-btn
          color="blue lighten-2"
          class="actionBtn"
          :disabled="!canAddEvent"
          @click="submitForm"
          >Ajouter</v-btn
        >
        <v-btn color="error" @click="cancel">Annuler</v-btn>
      </v-card-actions>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row class="d-flex flex-wrap">
          <v-col
            cols="12"
            class="d-flex flex-column flex-wrap"
            v-if="!isDirectLink"
          >
            <label>Reservation Type</label>
            <v-btn-toggle v-model="event_type" color="primary" mandatory>
              <v-btn>
                <v-icon>mdi-calendar</v-icon>
                Normal
              </v-btn>

              <v-btn>
                <v-icon>mdi-calendar-refresh</v-icon>
                Récurrente
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col class="d-flex" cols="12">
            <v-select
              label="Reservation"
              item-text="name"
              item-value="id"
              v-model="reservation"
              return-object
              :items="reservationItems"
            ></v-select>
          </v-col>
          <template v-if="!isRecursive">
            <v-checkbox
              v-model="commercial"
              label="Usage commercial de la salle (formation externe, prestation auprès d'un tier,...)"
            ></v-checkbox>
            <v-col cols="12">
              <datetime-picker
                label="Début *"
                v-model="start"
                :date-error="dateError"
                :error-msg="dateError ? dateErrorMsg : undefined"
              >
              </datetime-picker>
            </v-col>
            <v-col cols="12">
              <datetime-picker
                label="Fin *"
                v-model="end"
                :date-error="dateError"
                :error-msg="dateError ? dateErrorMsg : undefined"
              >
              </datetime-picker>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12">
              <fieldset>
                <legend>Période de la réservation initiale</legend>
                <v-row class="pa-2">
                  <v-col cols="5">
                    <date-picker
                      label="Heure Début *"
                      v-model="startTime"
                      :date-error="timeError"
                      :error-msg="timeError ? timeErrorMsg : undefined"
                      is-time
                    >
                    </date-picker>
                  </v-col>
                  <v-col cols="5" offset="2">
                    <date-picker
                      label="Heure Fin *"
                      v-model="endTime"
                      is-time
                      :date-error="timeError"
                      :error-msg="timeError ? timeErrorMsg : undefined"
                    >
                    </date-picker>
                  </v-col>
                </v-row>
              </fieldset>
            </v-col>

            <v-col cols="12">
              <fieldset>
                <legend>Intervalle de répétition</legend>
                <v-row class="pa-2">
                  <v-col cols="5">
                    <date-picker
                      label="Date Début *"
                      v-model="startDate"
                      :date-error="dateError"
                      :error-msg="dateError ? dateErrorMsg : undefined"
                    >
                    </date-picker>
                  </v-col>
                  <v-col cols="5" offset="2">
                    <date-picker
                      label="Date Fin *"
                      v-model="endDate"
                      :date-error="dateError"
                      :error-msg="dateError ? dateErrorMsg : undefined"
                    >
                    </date-picker>
                  </v-col>
                </v-row>
              </fieldset>
            </v-col>
            <v-col class="d-flex flex-column" cols="12" v-if="isRecursive">
              <label>Repete tout les</label>

              <v-btn-toggle v-model="event_repeat" color="primary">
                <v-btn
                  style="min-width: 30%;"
                  :disabled="!canRepeatDayly"
                  :elevation="event_repeat === 0 ? 8 : 0"
                >
                  Jours
                </v-btn>

                <v-btn
                  style="min-width: 30%;"
                  :disabled="!canRepeatWeekly"
                  :elevation="event_repeat === 1 ? 8 : 0"
                >
                  Semaines
                </v-btn>
                <v-btn
                  style="min-width: 30%;"
                  :elevation="event_repeat === 2 ? 8 : 0"
                  :disabled="!canRepeatMonthly"
                >
                  Mois
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </template>

          <v-col cols="12">
            <v-text-field
              v-model="email"
              :rules="emailRules"
              value="example"
              label="E-mail *"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              type="number"
              v-model="tel"
              label="Telephone *"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-text-field
              solo
              dense
              name="input-7-4"
              label="Nom *"
              :loading="eventLoading"
              clearable
              v-model="guest"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-textarea
              solo
              rows="2"
              dense
              name="input-7-4"
              label="Commentaire"
              :loading="eventLoading"
              clearable
              v-model="content"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
      * obligatoire
    </v-card-text>

    <v-overlay :value="overlay">
      <div class="d-flex flex-column justify-center align-center">
        <v-progress-circular
          indeterminate
          size="64"
          v-if="progress == 0"
        ></v-progress-circular>
        <v-progress-circular
          v-else
          :rotate="360"
          :size="100"
          :width="15"
          indeterminate
          color="primary"
        >
          <span class="white--text">
            <strong>{{ progress }} %</strong>
          </span>
        </v-progress-circular>
        <span class="mt-5">
          Creation en cours...
        </span>
      </div>
    </v-overlay>
  </v-card>
</template>
<script>
import moment from "../../plugins/moment";
import DatetimePicker from "../DatetimePicker.vue";
import DatePicker from "../DatePicker.vue";

export default {
  components: { DatePicker, DatetimePicker },
  props: {
    reservationItems: {
      type: Array,
      default() {
        return [];
      }
    },
    eventLoading: {
      type: Boolean,
      default: false
    },
    overlay: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Number,
      default: 0
    },
    event: {
      type: Object,
      default() {
        return {
          start: undefined,
          end: undefined
        };
      }
    }
  },
  data() {
    return {
      event_type: 0,
      event_repeat: -1,
      content: undefined,
      guest: undefined,
      tel: undefined,
      email: undefined,
      commercial: false,
      time: ["days", "weeks", "months"],
      reservation: undefined,
      start: undefined,
      end: undefined,
      code_pi: undefined,
      eqpt_name: undefined,
      startTime: undefined,
      endTime: undefined,
      startDate: undefined,
      endDate: undefined,
      dateErrorMsg: "Date Interval invalid",
      timeErrorMsg: "Time Interval invalid",
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+/.test(v) || "E-mail must be valid"
      ]
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.start = this.event.start;
      this.end = this.event.end;
      this.startDate = moment(this.start).format("YYYY-MM-DD");
      this.startTime = moment(this.start).format("HH:mm:ss");
      this.endDate = moment(this.end).format("YYYY-MM-DD");
      this.endTime = moment(this.end).format("HH:mm:ss");
    },
    cancel() {
      this.$emit("canceled");
      this.event.start = undefined;
      this.event.end = undefined;
      this.startDate = undefined;
      this.startTime = undefined;
      this.endDate = undefined;
      this.endTime = undefined;
    },
    addRecuresiveReservation() {
      const payload = {
        content: this.content,
        guest: this.guest,
        tel: this.tel,
        email: this.email,
        reservation: this.reservation,
        startDate: this.startDate,
        endDate: this.endDate,
        startTime: this.startTime,
        endTime: this.endTime,
        code_pi: this.reservation.code_pi,
        eqpt_name: this.reservation.name
      };

      let interval = moment(this.endDate).diff(
        moment(this.startDate),
        this.time[this.event_repeat]
      );
      this.$emit("addRecursiveReservation", {
        input: payload,
        repetition: this.event_repeat,
        interval: interval
      });
    },
    addReservation() {
      const payload = {
        content: this.content,
        guest: this.guest,
        tel: this.tel,
        email: this.email,
        reservation: this.reservation,
        start: this.start,
        end: this.end,
        code_pi: this.reservation.code_pi,
        eqpt_name: this.reservation.name,
        state: this.isDirectLink ? "0" : "1",
        commercial: this.commercial
      };

      let interval = moment(this.end).diff(
        moment(this.start),
        this.time[this.event_repeat]
      );
      this.$emit("addReservation", {
        input: payload,
        repetition: this.event_repeat,
        interval: interval
      });
    },
    submitForm() {
      if (this.event_type === 1) {
        this.addRecuresiveReservation();
      } else {
        this.addReservation();
      }
    },
    resetForm() {
      this.content = undefined;
      this.guest = undefined;
      this.tel = undefined;
      this.email = undefined;
      this.reservation = undefined;
      this.start = undefined;
      this.end = undefined;
      this.code_pi = undefined;
      this.eqpt_name = undefined;
      this.event_repeat = -1;
      this.event_type = 0;
      this.startDate = undefined;
      this.startTime = undefined;
      this.endDate = undefined;
      this.endTime = undefined;
      this.commercial = false;
    }
  },
  watch: {
    event: {
      handler: function() {
        if (!this.event.start) {
          this.resetForm();
        } else {
          this.initData();
        }
      },
      deep: true
    },
    canRepeatDayly() {
      if (this.event_repeat === -1 && this.canRepeatDayly) {
        this.event_repeat = 0;
      }
    },
    canRepeatWeekly() {
      if (this.event_repeat === 1 && !this.canRepeatWeekly) {
        this.event_repeat = 0;
      }
    },
    canRepeatMonthly() {
      if (this.event_repeat === 2 && !this.canRepeatMonthly) {
        this.event_repeat = 1;
      }
    }
  },
  computed: {
    dateError() {
      if (!this.isRecursive && this.start && this.end) {
        return moment(this.start).isAfter(moment(this.end));
      }
      if (this.isRecursive && this.startDate && this.endDate) {
        return moment(this.startDate).isAfter(moment(this.endDate));
      }
      return false;
    },
    timeError() {
      if (this.isRecursive && this.startTime && this.endTime) {
        return this.startTime > this.endTime;
      }
      return false;
    },
    repeatDuration() {
      return moment(this.end).diff(
        moment(this.start),
        this.time[this.event_type]
      );
    },
    canRepeatMonthly() {
      return moment(this.endDate).diff(moment(this.startDate), "months") > 0;
    },
    canRepeatWeekly() {
      return moment(this.endDate).diff(moment(this.startDate), "weeks") > 0;
    },
    canRepeatDayly() {
      return moment(this.endDate).diff(moment(this.startDate), "days") > 0;
    },
    canAddRecursive() {
      return (
        !!this.startDate &&
        !!this.startTime &&
        !!this.endDate &&
        !!this.endTime &&
        this.event_repeat > -1
      );
    },
    canAddNormalEvent() {
      return !!this.start && !!this.end;
    },
    canCreateNormalOrRecursiveEvent() {
      return (
        (this.isRecursive && this.canAddRecursive) ||
        (!this.isRecursive && this.canAddNormalEvent)
      );
    },
    canAddEvent() {
      return (
        this.canCreateNormalOrRecursiveEvent &&
        !!this.reservation &&
        !!this.guest &&
        //!!this.content &&
        !this.dateError &&
        !!this.tel &&
        !!this.email
      );
    },
    isRecursive() {
      return this.event_type === 1;
    },
    isDirectLink() {
      return this.$route.meta.directLink;
    }
  }
};
</script>
