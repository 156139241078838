<template>
  <v-container fluid fill-height class="mx-0 px-md-3 px-0">
    <slot v-bind:exposedData="exposedData"></slot>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import USER_API from "../../api/user";
import { getStatus, loadStates, groupBy, flatten } from "../../utils";
import { EventBus } from "../../event-bus";
import moment from "../../plugins/moment";

export default {
  name: "Ticket",
  props: {
    filtered: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tickets: [],
      ticketsFields: [],
      loaded: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters([
      "getUserProfile",
      "getEntity",
      "getActiveEntity",
      "getTickets",
      "getEquipments",
      "getTicketLoadStatus",
      "getEquipmentLoadStatus",
      "getAllITILSolutions",
      "getAllITILFollowups",
      "getTicketsTasks",
      "itemLoaded",
      "getEquipmentByType",
      "getTypeName"
    ]),
    display() {
      return this.getActiveEntity.id === this.$route.params.id;
    },
    filteredTickets() {
      return this.tickets.filter(
        ticket =>
          this.categories.includes(ticket.itilcategories_id) ||
          (!ticket.id && +ticket.entities_id === +this.$route.params.id)
      );
    },
    usedTickets() {
      return this.filtered ? this.filteredTickets : this.tickets;
    },
    getStates() {
      let states = this.getActiveEntity.state;
      return states ? JSON.parse(states) : { location: "Emplacement" };
    },
    ticketByItemTypes() {
      const data = groupBy(this.usedTickets, x => x.itemtype);
      const states = Object.keys(this.getStates);
      states.forEach(type => {
        if (!data[type]) {
          data[type] = [];
        }
      });
      return data;
    },
    exposedData() {
      return {
        tickets: this.usedTickets,
        loaded: this.loaded,
        loading: this.loading,
        tickets_items: this.ticketByItemTypes
      };
    },
    selectedEntity() {
      if (this.$route.meta.directLink) {
        return this.getActiveEntity;
      }

      return this.getEntity.find(
        entity => entity.id === Number.parseInt(this.$route.params.id)
      );
    },
    categories() {
      if (
        this.getEntity.filter(el => el.level == 3).length > 0 &&
        this.getEntity.filter(el => el.level == 3)[0].id == 308
      ) {
        return ["BUG", "FEAT"];
      } else {
        return ["Projet", "Commissionnement", "Exploitation", "Public"];
      }
    }
  },
  methods: {
    ...mapActions(["loadEquipment", "loadTickets", "loadItemType"]),
    async loadData() {
      this.loading = true;
      if (this.getTicketLoadStatus === loadStates.INITIAL_STATE) {
        let { data: tickets } = await USER_API.getTicketsEnt(
          this.$route.params.id
        );
        //this.equipments = (await USER_API.getEquipment()).data;
        //this.loadEquipment(this.equipments);
        await this.getEquipmentsData(tickets);
      } else {
        if (this.selectedEntity) {
          this.tickets = this.getTickets.filter(ticket => {
            return (
              ticket.entities_id === this.selectedEntity.completename ||
              (!ticket.id && ticket.entities_id === +this.$route.params.id)
            );
          });
        }

        //this.equipments = this.getEquipments;
        this.loaded = true;
        this.loading = false;
      }
      this.getAdditionalTicket();
    },
    async getTicketItemData(ticketsData) {
      let newTickets = ticketsData.filter(
        ticket => ticket.name.startsWith("{") && ticket.name.endsWith("}")
      );
      newTickets = newTickets.map(ticket => {
        try {
          const name = JSON.parse(ticket.name);
          ticket.name = name.titre;
          ticket.itemtype = name.itemtype;
          ticket.itemtypeID = name.itemtypeID;
          return ticket;
        } catch {
          ticket.itemtype = ticket.name;
          ticket.itemtypeID = ticket.name;
          return ticket;
        }
      });
      newTickets = groupBy(newTickets, x => x.itemtype);
      let data = await Promise.all(
        Object.keys(newTickets).map(async itemType => {
          try {
            await this.loadItemType(itemType);
            let tickets = newTickets[itemType];
            tickets = tickets.map(el => {
              el.equipment = this.getTypeName(itemType, el.itemtypeID);
              return el;
            });

            return tickets;
          } catch (error) {
            return newTickets;
          }
        })
      );

      return flatten(data);
    },

    async getEquipmentsData(ticketsData) {
      const ticketWithItemData = await this.getTicketItemData(ticketsData);

      this.tickets = ticketsData.map(ticket => {
        if (
          (ticket.name.startsWith("{") && ticket.name.endsWith("}")) ||
          (ticket.itemtype && ticket.itemtypeID)
        ) {
          const tk = ticketWithItemData.find(el => el.id === ticket.id);
          ticket = tk ? tk : ticket;
          if (!tk) {
            ticket.equipment =
              ticket.locations_id === 0
                ? "Sans Equipement"
                : ticket.locations_id;
          }
        } else {
          ticket.equipment =
            ticket.locations_id === 0 ? "Sans Equipement" : ticket.locations_id;
        }
        ticket.no_equipment = ticket.locations_id === 0;

        const { date, takeintoaccount_delay_stat } = ticket;
        ticket.attributdate =
          takeintoaccount_delay_stat > 0
            ? moment(date)
                .add(takeintoaccount_delay_stat, "s")
                .format("DD-MM-YYYY HH:mm")
            : "";

        ticket.statusName = getStatus(ticket.status, this.$root.$i18n.locale);
        return ticket;
      });
      this.loaded = true;
      this.loading = false;
      this.loadTickets(this.tickets);
    },
    updateTicket(id) {
      const oldTicket = this.tickets.find(ticket => ticket.id === id);
      const oldTicketIndex = this.tickets.findIndex(ticket => ticket.id === id);
      USER_API.getTicket(id).then(({ data }) => {
        data.equipment = oldTicket.equipment;
        this.$set(this.tickets, oldTicketIndex, data);
      });
    },
    updateTicketLastComment(content, ticket_id) {
      const oldTicket = this.tickets.find(ticket => ticket.id === ticket_id);
      const oldTicketIndex = this.tickets.findIndex(
        ticket => ticket.id === ticket_id
      );
      oldTicket.last_comment = content;
      this.$set(this.tickets, oldTicketIndex, oldTicket);
    },
    async getLastComment(ticketID) {
      const followUps = this.getAllITILFollowups.filter(
        fl => fl.items_id === ticketID
      );
      const tasks = this.getTicketsTasks.filter(fl => fl.items_id === ticketID);
      const solutions = this.getAllITILSolutions.filter(
        fl => fl.items_id === ticketID
      );

      let alldata = followUps.concat(tasks).concat(solutions);

      let sortedData = alldata.sort((a, b) => {
        if (moment(a.date_creation).isAfter(moment(b.date_creation))) {
          return -1;
        } else if (moment(b.date_creation).isAfter(moment(a.date_creation))) {
          return 1;
        } else {
          return 0;
        }
      });

      const lastComment = sortedData[0] ? sortedData[0].content : null;

      return lastComment;
    },
    async getAdditionalTicket() {
      Object.keys(this.getStates).forEach(async el => {
        if (el.toLowerCase().startsWith("plugingenericobject")) {
          await this.loadItemType(el);
        }
        let equips = this.getEquipmentByType(el);
        const equips_data = equips ? equips.data : [];
        const addTickets = this.getAdditionalsTickets(equips_data, el);
        addTickets.forEach(tick => {
          this.tickets.push(tick);
        });
      });
    },
    getAdditionalsTickets(equipements, type) {
      const addTickets = [];
      equipements.forEach(equip => {
        const hasTickets = this.tickets.find(
          tick =>
            tick.equipment === equip.name &&
            tick.entities_id == equip.entities_id
        );
        if (!hasTickets) {
          addTickets.push({
            equipment: equip.name,
            entities_id: equip.entities_id,
            itemtype: type
          });
        }
      });
      return addTickets;
    }
  },
  created() {
    this.loadData();
  },
  mounted() {
    EventBus.$on("ticket-resolu", this.updateTicket);
    EventBus.$on("new-content", this.updateTicketLastComment);
  },
  destroyed() {
    // Destroy event listening
    EventBus.$off("ticket-resolu");
    EventBus.$off("new-content");
  },
  watch: {
    selectedEntity() {
      this.loadData();
    }
  }
};
</script>

<style scoped></style>
