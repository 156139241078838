<template>
  <v-card style="position: relative; height: 380px">
    <v-card class="elevation-0">
      <v-card-title class="headline display-1" v-if="isMobile">
        {{ $t("prestation.monthly_benefit_1") }}
      </v-card-title>
      <v-card-title class="headline display-1" v-else>
        {{ $t("prestation.monthly_benefit_2") }}
      </v-card-title>
      <v-card-text>
        <bar-chart
          :chart-data="collection"
          :height="300"
          :width="null"
          v-if="tickets.length > 0"
        ></bar-chart>
        <h4 v-else>{{ $t("labels.no_data") }}</h4>
      </v-card-text>
    </v-card>
  </v-card>
</template>
<script>
import { DefaultChartProps } from "../../mixins/default-chart-props";
import BarChart from "./PrestationChart";
import Chart from "chart.js";
import moment from "moment";
import { uniqueElementBy } from "../../utils";

const color = Chart.helpers.color;

window.chartColors = [
  "rgb(255, 99, 132)",
  "rgb(255, 159, 64)",
  "rgb(255, 205, 86)",
  "rgb(75, 192, 192)",
  "rgb(54, 162, 235)",
  "rgb(153, 102, 255)",
  "rgb(201, 203, 207)"
];

export default {
  name: "PChart",
  components: { BarChart },
  mixins: [DefaultChartProps],
  computed: {
    collection() {
      const data = this.labels.map(el => {
        const ticks = this.tickets.filter(
          ticket =>
            moment(ticket.date, "DD-MM-YYYY HH:mm").format("MMMM") === el
        );
        return ticks;
      });

      const dataset = {
        labels: this.labels,
        datasets: this.perimetres.map((category, index) => {
          return {
            label: category,
            data: data.map(tickets => {
              const categoryTickets = tickets.filter(ticket => {
                try {
                  const name = JSON.parse(ticket.name);
                  return name.titre === category;
                } catch {
                  return false;
                }
              });
              return categoryTickets.length;
            }),
            backgroundColor: color(window.chartColors[index])
              .alpha(0.5)
              .rgbString(),
            borderColor: window.chartColors[index],
            borderWidth: 1
          };
        })
      };

      return dataset;
    },
    labels() {
      const currentMonth = moment().format("MMMM");
      const lastMonth = moment()
        .subtract(1, "months")
        .format("MMMM");
      const lastMonth2 = moment()
        .subtract(2, "months")
        .format("MMMM");
      const lastMonth3 = moment()
        .subtract(3, "months")
        .format("MMMM");

      return [lastMonth3, lastMonth2, lastMonth, currentMonth];
    },
    perimetres() {
      const names = this.tickets.map(ele => JSON.parse(ele.name).titre);
      const perimetres = uniqueElementBy(
        names,
        (a, b) => a.toLowerCase() === b.toLowerCase()
      );
      return perimetres;
    },
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.lg
      );
    }
  }
};
</script>
<style scoped>
.chart_ {
  height: 400px !important;
}
</style>
