<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-card class="elevation-3 my-6">
    <v-col lg="12" class="px-0 px-md-3">
      <v-card v-if="isMobile" flat>
        <v-row no-gutters class="pa-2">
          <v-col cols="9">
            <v-card-title class="headline display-1">
              Prestations
            </v-card-title>
          </v-col>
          <v-col cols="3" md="6" class="mt-3 pr-2">
            <v-row justify="center">
              <v-dialog v-model="docFilterDialog">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark v-bind="attrs" v-on="on">
                    Filtres
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Filtres</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-select
                            label="par périmètre"
                            :items="perimetres"
                            clearable
                            v-model="selectedPerimetre"
                            multiple
                            chips
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-daterange
                            v-model="dateRange"
                            display-format="dd-MM-yyyy"
                            no-title
                            :menu-props="menuProps"
                            :presets="presets"
                          ></v-daterange>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="docFilterDialog = false"
                      >OK
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-col>

          <v-text-field
            v-model="search"
            append-icon="search"
            :label="$t('tableStatus.searchAll')"
            single-line
            hide-details
            class="mt-n2"
          ></v-text-field>
        </v-row>
      </v-card>
      <v-card flat v-else>
        <v-card-title class="headline display-1">
          {{ $t("header.list_performance") }}
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            :label="$t('tableStatus.searchAll')"
            single-line
            hide-details
            class="mt-n2"
          >
          </v-text-field>
        </v-card-title>
        <v-card-text class="elevation-2 mt-2 grey lighten-5">
          <v-row>
            <v-col md="6" sm="12" class="mt-n5 mb-n5">
              <v-select
                :label="$t('tableStatus.filter_perimeter')"
                :items="perimetres"
                clearable
                v-model="selectedPerimetre"
                multiple
              ></v-select>
            </v-col>
            <v-col md="6" sm="12" class="mt-n5 mb-n5">
              <v-daterange
                v-model="dateRange"
                display-format="dd-MM-yyyy"
                no-title
                :menu-props="menuProps"
                :presets="presets"
                :start-label="$t('labels.start_date')"
                :end-label="$t('labels.end_date')"
                :separator-label="$t('labels.to')"
                :locale="$root.$i18n.locale"
              ></v-daterange>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card-text class="px-2 px-md-4 mb-6">
        <v-data-table
          :key="updateKey"
          :headers="headers"
          :items="filteredItems"
          item-key="id"
          :loading="loading"
          class="table is_bordered"
          sort-by="date"
          sort-desc
          group-by="equipment"
          :mobile-breakpoint="this.$vuetify.breakpoint.thresholds.md"
          :group-desc="false"
          :search="search"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          @page-count="pageCount = $event"
        >
          <template v-slot:group.header="{ toggle, group, isOpen }">
            <td colspan="4" class="pl-0">
              <v-btn @click="toggle()" icon>
                <v-icon
                  >mdi-{{
                    isOpen
                      ? "arrow-up-drop-circle-outline"
                      : "arrow-down-drop-circle-outline"
                  }}</v-icon
                >
              </v-btn>
              <span class="primary--text">{{ group }}</span>
            </td>
          </template>

          <template v-slot:item="{ item, isMobile }">
            <mobile-row-presta
              :item="item"
              :headers="headers"
              v-if="isMobile"
              @expand="expandRow"
              :is-expanded="shoulExpand(item.id)"
              @n-expand="removeFromExpanded"
            >
              <template v-slot:name="{ item }">
                {{ JSON.parse(item.name).titre }}
              </template>
              <template v-slot:photo="{ item }">
                <div>
                  <v-btn
                    color="primary"
                    fab
                    small
                    v-if="getImage(item.id)"
                    @click="downloadImage(item.id)"
                    ><v-icon>mdi-file-eye</v-icon></v-btn
                  >
                </div>
              </template>
            </mobile-row-presta>
            <c-row v-else :item="item" :headers="headers" rtl>
              <template v-slot:id="{ item }">
                {{ formatID(item.id) }}
              </template>
              <template v-slot:name="{ item }">
                {{ JSON.parse(item.name).titre }}
              </template>
              <template v-slot:content="{ item }">
                <div
                  class="incident description"
                  :class="{ 'test-class': isMobile }"
                  v-if="item.content"
                  v-html="decodeContent(item.content)"
                ></div>
              </template>
              <template v-slot:date="{ item }">
                {{
                  item.date ? moment(item.date).format("DD-MM-YYYY HH:mm") : ""
                }}
              </template>
              <template v-slot:photo="{ item }">
                <v-btn
                  color="primary"
                  v-if="getImage(item.id)"
                  @click="downloadImage(item.id)"
                  ><v-icon>mdi-file-eye</v-icon>Open</v-btn
                >
              </template>
            </c-row>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"> </v-pagination>
        </div>
      </v-card-text>
    </v-col>
  </v-card>
</template>
<script>
import {
  formatID,
  remove,
  mobileHeaders,
  uniqueElementBy
  //blobToImage
} from "../../utils";
import { Decoder } from "../../mixins/decodeContent";
import moment from "../../plugins/moment";
import Row from "../Row";
import USER_API from "../../api/user";
import MobileRowPresta from "./MobileRowPresta";
import { mapGetters } from "vuex";
import VDaterange from "../../components/VDaterange";
import TICKET_API from "../../api/ticket";

export default {
  name: "List",
  props: {
    tickets: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    loaded: {
      type: Boolean,
      default: false
    }
  },
  components: { [Row.name]: Row, MobileRowPresta, VDaterange },
  data() {
    return {
      list: [],
      search: "",
      expanded: [],
      mobileHeaders: mobileHeaders(this.$root.$i18n.locale),
      selectedTicket: undefined,
      updateKey: 0,
      selectedDate: null,
      docFilterDialog: false,
      dialog: false,
      selectedPerimetre: [],
      categories: [
        "Projet",
        "Exploitation",
        "Commissionnement",
        "Public",
        "Non défini"
      ],
      selectedCategory: [
        "Projet",
        "Exploitation",
        "Commissionnement",
        "Public"
      ],
      page: 1,
      pageCount: 0,
      headers: [
        {
          text: "ID",
          align: "center",
          sortable: "false",
          value: "id",
          class: "t-row"
        },
        {
          text: this.$t("header.perimeter"),
          align: "center",
          sortable: "false",
          value: "name",
          class: "t-row"
        },
        {
          text: this.$t("header.date"),
          align: "center",
          sortable: "false",
          value: "date",
          class: "t-row"
        },
        {
          text: this.$t("header.society"),
          align: "center",
          sortable: "false",
          value: "content",
          class: "t-row"
        },
        {
          text: this.$t("header.image"),
          align: "center",
          sortable: false,
          value: "photo",
          class: "t-row"
        }
      ],
      dateMenu: false,
      dateFormatted: null,
      dateRange: {},
      menuProps: { offsetY: true, closeOnContentClick: false },
      presets: [
        {
          label: this.$t("index.today"),
          range: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")]
        },
        {
          label: this.$t("index.yesterday"),
          range: [
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD"),
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD")
          ]
        },
        {
          label: this.$t("index.last_days"),
          range: [
            moment()
              .subtract(30, "days")
              .format("YYYY-MM-DD"),
            moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD")
          ]
        }
      ],
      documents: []
    };
  },
  mixins: [Decoder],
  methods: {
    formatID,
    moment,
    customSort(items, index, sortDesc) {
      // The following is informations as far as I researched.
      // items: 'tickets' items
      // index: Enabled sort headers value. (black arrow status).
      // sortDesc: Whether enabled sort headers is desc
      if (!index[1]) return items;

      items.sort((a, b) => {
        const attribute = index[1];
        let sortA = a[attribute];
        let sortB = b[attribute];
        const dateAttributes = ["date", "attributdate", "date_mod"];
        if (dateAttributes.includes(attribute)) {
          if (!sortA) {
            sortA = "2010-01-01 00:00";
          }
          if (!sortB) {
            sortB = "2010-01-01 00:00";
          }
          const dateA = moment(sortA, "YYYY-MM-DD HH:mm:ss");
          const dateB = moment(sortB, "YYYY-MM-DD HH:mm:ss");

          if (sortDesc[0]) {
            if (dateB.isAfter(dateA)) {
              return 1;
            } else if (dateA.isAfter(dateB)) {
              return -1;
            } else {
              return 0;
            }
          } else {
            if (dateA.isAfter(dateB)) {
              return 1;
            } else if (dateB.isAfter(dateA)) {
              return -1;
            } else {
              return 0;
            }
          }
        }
      });

      return items;
    },
    expandRow(item) {
      this.expanded.push(item);
    },
    shoulExpand(id) {
      return this.expanded.includes(id);
    },
    removeFromExpanded(id) {
      this.expanded = remove(this.expanded, r => r.id === id);
    },
    titleText(selectedTicket) {
      try {
        const title = this.getActiveEntity.completename.replace(" ", "");
        const parentsEntities = title.split(">");
        let assetTitle = "";
        if (this.$vuetify.breakpoint.smAndDown) {
          assetTitle =
            parentsEntities[2].slice(0, 10) +
            " > " +
            parentsEntities[3].slice(0, 10);

          assetTitle = selectedTicket.no_equipment
            ? assetTitle
            : assetTitle + " > " + selectedTicket.equipment.slice(0, 10);
        } else {
          assetTitle = parentsEntities[2] + " > " + parentsEntities[3];

          assetTitle = selectedTicket.no_equipment
            ? assetTitle
            : assetTitle + " > " + selectedTicket.equipment;
        }
        return assetTitle;
      } catch (error) {
        return "";
      }
    },
    formatDate(date) {
      if (!date) return null;

      return moment(date).format("DD-MM-YYYY");
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
    filterByDate(date) {
      if (!this.dateRange.start && !this.dateRange.end) return true;
      return this.dateRange.start <= date && this.dateRange.end >= date;
    },
    getImage(entityId) {
      const images = this.documents.filter(el => el.items_id == entityId);
      return images && images.length > 0 ? images[0] : null;
    },
    async loadDocumentsImages() {
      try {
        const { data } = await TICKET_API.getDocumentsItems();
        this.documents = data;
      } catch {
        this.documents = [];
      }
    },
    downloadImage(entityId) {
      const doc = this.getImage(entityId);
      USER_API.getDocument(doc.documents_id).then(async response => {
        USER_API.downloadDocument(response.data);
      });
    },
    loadEquiment() {
      this.tickets.forEach(ticket => {
        const equipment = this.getEquipments.filter(
          el => el.id == ticket.equipment
        );
        if (equipment && equipment.length > 0) {
          ticket.equipment = equipment[0].name;
        }
      });
    }
  },
  computed: {
    ...mapGetters(["getActiveEntity", "getDocumentsImages", "getEquipments"]),
    isMobile() {
      return (
        this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md
      );
    },
    itemsPerPage() {
      return this.isMobile ? 10 : 30;
    },
    perimetres() {
      const names = this.tickets.map(ele => ele.name);
      const perimetres = uniqueElementBy(names, (a, b) => a === b);
      return perimetres;
    },
    filteredItems() {
      return this.tickets.filter(i => {
        const dateCreation = moment(i.date, "YYYY-MM-Do HH:mm ss").format(
          "YYYY-MM-DD"
        );
        return (
          (this.selectedPerimetre.length === 0 ||
            this.selectedPerimetre.includes(i.name)) &&
          this.filterByDate(dateCreation)
        );
      });
    }
  },
  async mounted() {
    await this.loadDocumentsImages();
    this.loadEquiment();
  },
  watch: {
    selectedDate() {
      this.dateFormatted = this.formatDate(this.selectedDate);
    }
  }
};
</script>
<style scoped></style>
